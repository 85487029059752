<template>
  <div style="position: relative">
    <div class="export_btn">
      <el-button size="small" type="success" @click="daochu()" icon="Folder" :loading="btnLoading">
        导出
      </el-button>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="账套" name="1"></el-tab-pane>
      <el-tab-pane label="授权" name="2"></el-tab-pane>
      <el-tab-pane label="票据采集" name="3"></el-tab-pane>
      <el-tab-pane label="税种申报" name="4"></el-tab-pane>
      <el-tab-pane label="税种检查" name="5"></el-tab-pane>
      <el-tab-pane label="社保申报" name="6"></el-tab-pane>
      <div class="top_btns">
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <qzf-search-period
          v-model:period="listQuery.period"
          @success="getList"
        ></qzf-search-period>
        <el-input
          placeholder="请输入企业名称"
          v-model="listQuery.orgName"
          style="width: 160px; margin-left: 5px"
          class="filter-item"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" size="small" @click="getList" icon="Search"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="list"
        :height="contentStyleObj"
        border
        v-loading="loading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column
          prop="branch_name"
          label="公司名称"
          min-width="200"
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          prop="new_account"
          label="新建账套"
          sortable
          v-if="activeName == '1'"
        >
        </el-table-column>
        <el-table-column
          prop="tax_declaration"
          label="税款申报"
          sortable
          v-if="activeName == '1'"
        >
        </el-table-column>
        <el-table-column
          prop="zero_declaration"
          label="零申报"
          sortable
          v-if="activeName == '1'"
        >
        </el-table-column>
        <el-table-column
          prop="stopped_customer"
          label="停报客户"
          sortable
          v-if="activeName == '1'"
        >
        </el-table-column>
        <el-table-column
          prop="recycle_bin"
          label="回收站"
          sortable
          v-if="activeName == '1'"
        >
        </el-table-column>
        <el-table-column prop="total" label="合计" sortable v-if="activeName == '1'">
        </el-table-column>
        <el-table-column
          prop="unauthorized"
          label="未授权"
          sortable
          v-if="activeName == '2'"
        >
        </el-table-column>
        <el-table-column
          prop="authorized"
          label="已授权"
          sortable
          v-if="activeName == '2'"
        >
        </el-table-column>
        <el-table-column
          prop="authorization_failed"
          label="授权失败"
          sortable
          v-if="activeName == '2'"
        >
        </el-table-column>
        <el-table-column
          prop="disconnected"
          label="已掉线"
          sortable
          v-if="activeName == '2'"
        >
        </el-table-column>
        <el-table-column
          prop="in_authorization"
          label="授权中"
          sortable
          v-if="activeName == '2'"
        >
        </el-table-column>
        <el-table-column
          prop="no_authorization_needed"
          label="无需授权"
          sortable
          v-if="activeName == '2'"
        >
        </el-table-column>
        <el-table-column
          label="勾选认证"
          align="center"
          v-if="activeName == '3'"
        >
          <el-table-column label="成功" prop="success" sortable> </el-table-column>
          <el-table-column label="失败" prop="failure" sortable> </el-table-column>
          <el-table-column label="未确认" prop="not_confirmed" sortable>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="发票采集"
          align="center"
          v-if="activeName == '3'"
        >
          <el-table-column label="成功" prop="success_again" sortable> </el-table-column>
          <el-table-column label="失败" prop="failure_again" sortable> </el-table-column>
          <el-table-column label="未采集" prop="not_collected" sortable>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="银行采集"
          align="center"
          v-if="activeName == '3'"
        >
          <el-table-column label="银行已授权" prop="bank_authorized" sortable>
          </el-table-column>
          <el-table-column label="银行未授权" prop="bank_not_authorized" sortable>
          </el-table-column>
          <el-table-column label="成功" prop="bank_success" sortable> </el-table-column>
          <el-table-column label="失败" prop="bank_failure" sortable> </el-table-column>
          <el-table-column label="未采集" prop="not_collected_again" sortable>
          </el-table-column>
        </el-table-column>
        <el-table-column label="国税" align="center" v-if="activeName == '4'">
          <el-table-column label="未申报" prop="unsubmitted_tax_count" sortable>
          </el-table-column>
          <el-table-column
            label="进行中（发起中/排队中/申报中）"
            prop="in_progress"
            min-width="220"
            sortable
          >
          </el-table-column>
          <el-table-column label="申报成功" prop="tax_declaration_success" sortable>
          </el-table-column>
          <el-table-column label="申报失败" prop="tax_declaration_failed" sortable>
          </el-table-column>
          <el-table-column label="无需申报" prop="no_declaration_needed" sortable>
          </el-table-column>
          <el-table-column label="本期应申报" prop="current_tax_count" sortable>
          </el-table-column>
        </el-table-column>
        <el-table-column label="个税" align="center" v-if="activeName == '4'">
          <el-table-column label="未申报" prop="not_submitted" sortable>
          </el-table-column>
          <el-table-column
            label="进行中（发起中/排队中/申报中/申报扣款中）"
            prop="in_progress_payment"
            sortable
            min-width="280"
          >
          </el-table-column>
          <el-table-column label="申报成功" prop="declaration_success" sortable>
          </el-table-column>
          <el-table-column
            label="申报失败或异常（超时失败/申报异常/申报失败/扣款异常）"
            prop="declaration_error"
            sortable
            min-width="325"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="not_checked"
          label="未检查"
          sortable
          v-if="activeName == '5'"
        ></el-table-column>
        <el-table-column
          prop="in_progress_check"
          label="进行中（发起中、排队中、检查中）"
          min-width="240"
          sortable
          v-if="activeName == '5'"
        ></el-table-column>
        <el-table-column
          prop="completed"
          label="完成"
          sortable
          v-if="activeName == '5'"
        ></el-table-column>
        <el-table-column
          prop="failed"
          label="失败"
          sortable
          v-if="activeName == '5'"
        ></el-table-column>
        <el-table-column
          prop="not_submitted_again"
          label="应申报(有社保)"
          sortable
          v-if="activeName == '6'"
        ></el-table-column>
        <el-table-column
          prop="in_progress_again"
          label="进行中（发起中/排队中/申报中）"
          min-width="220"
          sortable
          v-if="activeName == '6'"
        ></el-table-column>
        <el-table-column
          prop="declaration_success_again"
          label="申报成功/已申报过"
          sortable
          v-if="activeName == '6'"
        ></el-table-column>
        <el-table-column
          prop="declaration_failed_again"
          label="申报失败"
          sortable
          v-if="activeName == '6'"
        ></el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList"/>
      </div>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { currentAccountPeriod } from "@/utils";
import { groupReportData,exportReportData } from "@/api/org";
const { proxy } = getCurrentInstance();
const activeName = ref("1");
const listQuery = ref({
  name: "",
  period: currentAccountPeriod(),
  page: 1,
  limit: 20,
});
const contentStyleObj = ref({});
const list = ref([]);
const loading = ref(false);
const total = ref(0)
const btnLoading = ref(false)
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(250);
  getList();
});
const getList = () => {
  console.log(1);
  loading.value = true
  groupReportData(listQuery.value).then((res) => {
    loading.value = false
    if(res.data.msg == 'success'){
      list.value = res.data.data.list ? res.data.data.list : [];
      console.log(list.value);
      total.value = res.data.data.total
    }
  });
};
const daochu = () =>{
  btnLoading.value = true
  exportReportData(listQuery.value).then(res => {
    btnLoading.value = false
    if(res.data.msg == 'success'){
      window.open(res.data.data)
    }
  })
}
</script>
<style lang="scss" scoped>
.top_btns {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.export_btn {
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
</style>
