<template>
  <div class="app-container auto_home">
    <div class="auto_content">
      <div class="auto_content_size">
        <div style="width: 50px">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each" @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div class="each_text">
            <div>异常</div>
            <a><span>{{ info.countErr }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div class="each_text">
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span>家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div class="each_text">
            <div>已确认</div>
            <a>
              <span>{{ info.countYqr }}</span>家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width:100px"
          @change="getNewList"></qzf-search-period>
      </div>
    </div>
    <div class="top_select">
      <div class="left_select">
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入公司名称" v-model.trim="listQuery.name" style="width:160px"
          @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" class="search_btn" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 15px;width:630px" label-width="130px" size="small" :inline="true">
            <el-form-item label="纳税人类型 ：">
              <selecttaxtype v-model:type="listQuery.type" class="w-140"></selecttaxtype>
            </el-form-item>
            <el-form-item label="税局 ：">
              <addressList v-model:type="listQuery.address" class="w-140"></addressList>
            </el-form-item>
            <el-form-item label="授权状态：">
              <selectAccreditStatus v-model:type="listQuery.keepStatus" class="w-140 margin0"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="进项采集任务结果：">
              <selectBussinessCjStatus v-model:allStatus="listQuery.inAllStatus"></selectBussinessCjStatus>
            </el-form-item>
            <el-form-item label="销项采集任务结果：">
              <selectBussinessCjStatus v-model:allStatus="listQuery.outAllStatus" :type="false">
              </selectBussinessCjStatus>
            </el-form-item>
            <el-form-item label="进项采集失败原因：">
              <el-input v-model="listQuery.inErrLog" clearable class="w-140 margin0"></el-input>
            </el-form-item>
            <el-form-item label="销项采集失败原因：">
              <el-input v-model="listQuery.outErrLog" clearable class="w-140 margin0"></el-input>
            </el-form-item>
          </el-form>
        </search>
        <el-radio-group v-model="listQuery.inType" class="ml-5" size="small" @change="getList">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button label="1">有进项</el-radio-button>
          <el-radio-button label="0">无进项</el-radio-button>
        </el-radio-group>
        <el-radio-group v-model="listQuery.outType" class="ml-5" size="small" @change="getList">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button label="1">有销项</el-radio-button>
          <el-radio-button label="0">无销项</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <el-button @click="confirmAll()" type="success" size="small" :disabled="!$buttonStatus('zdjz_fpqr')">
          <el-icon>
            <Checked />
          </el-icon> <span>确认发票信息</span>
        </el-button>
        <el-button type="primary" size="small" @click="copyTax" :disabled="!$buttonStatus('sktj_fzsk')"
          icon="DocumentCopy">复制发票
        </el-button>
        <el-button-group style="margin-left:12px">
          <el-button type="success" size="small" @click="sendTax" :loading="sendLoading"
            :disabled="!$buttonStatus('sktj_fzsk')"><i class="iconfont icon-weixin"></i>&nbsp;微信群发确认
          </el-button>
          <el-button type="success" size="small" @click="bindWechat" :disabled="!$buttonStatus('sktj_fzsk')">绑定微信
          </el-button>
        </el-button-group>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange"
      v-loading="loading" @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column label="编号" align="center" width="70" fixed="left" prop="sort" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId">

          </TableSortCell>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="name" label="公司名称" min-width="220" fixed="left">
        <template #default="scope">
          <TagNameCopy :row="scope.row" idKey="comId">
            <template v-slot>
              <el-tooltip placement="top" effect="dark">
                <template #content>
                  <div>{{ scope.row.wxNc ? `已绑定：${scope.row.wxNc}` : '暂未绑定微信昵称或群聊名称，点击绑定' }}</div>
                </template>
                <i class="iconfont icon-weixintuisong" :style="{ color: !scope.row.wxNc ? '#888' : '' }"
                  @click="setWechat(scope.row)"></i>
              </el-tooltip>
            </template>
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column align="center" label="地区" width="75">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inAmount1" label="进项发票金额" min-width="140">
        <template #default="scope">
          <div>
            <div v-if="scope.row.type == 2">
              <div v-if="scope.row.inAmount1 * 1">金额：{{ parseFloat(scope.row.inAmount1) }}</div>
              <div v-else>金额：0.00</div>
              <div v-if="(scope.row.inAmount3 * 100 - scope.row.inAmount1 * 100) / 100 != 0">税额：{{ ((scope.row.inAmount3
                * 100 -
                scope.row.inAmount1 * 100) / 100).toFixed2() }}</div>
              <div v-else>税额：0.00</div>
            </div>
            <div v-if="scope.row.type == 1">
              <div v-if="scope.row.inAmount3 * 1">价税合计：{{ Number(scope.row.inAmount3).toFixed2() }}</div>
              <div v-else>价税合计：0.00</div>
              <div v-if="(scope.row.inAmount3 * 100 - scope.row.inAmount1 * 100) / 100 != 0">税额：{{ ((scope.row.inAmount3
                * 100 -
                scope.row.inAmount1 * 100) / 100).toFixed2() }}</div>
              <div v-else>税额：0.00</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="进项采集任务状态" min-width="200">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <el-tooltip
                  :disabled="(scope.row.inErrLog ? scope.row.inErrLog : '' + scope.row.inBusinessLog ? scope.row.inBusinessLog : '') == '' ? true : false"
                  class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div
                      v-html="scope.row.inErrLog ? scope.row.inErrLog : '' + scope.row.inBusinessLog ? scope.row.inBusinessLog : ''">
                    </div>
                  </template>
                  <div>
                    <div style="display:inline-block"
                      v-if="scope.row.inImg && (scope.row.inTaskStatus == 3 && scope.row.inBusinessStatus != 2)"
                      class="demo-image__preview">
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;"
                        v-if="scope.row.inBusinessStatus == 5" @click="open(scope.row, scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else
                        @click="open(scope.row, scope.$index)"></i>
                    </div>
                    <i v-else :class="$batchSendIconCj(scope.row.inTaskStatus, scope.row.inBusinessStatus)"></i>
                    <span style="padding-left: 0 ;">{{ $batchSendCj(scope.row.inTaskStatus,
                      scope.row.inBusinessStatus) }}</span>
                    <span v-if="scope.row.inAmount2 != '' && scope.row.inBusinessStatus == 2"
                      style="margin-left: 5px;">{{
                        scope.row.inAmount2 }}张</span>
                    <i class="iconfont icon-wenhao"
                      v-if="(scope.row.inErrLog && scope.row.inErrLog != '成功') || (scope.row.inBusinessLog && scope.row.inBusinessLog != '成功')"
                      style="font-size:15px;color: red;margin-left: 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <span>{{ scope.row.inEndAt }}</span>
            </div>
            <p style="padding-top: 12px;"><el-button @click="declareDetail(scope.row, 'in')" size="small"
                link>采集记录</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="outAmount1" label="销项发票金额" min-width="140">
        <template #default="scope">
          <div>
            <div v-if="scope.row.type == 2">
              <div v-if="scope.row.outAmount1 * 1">金额：{{ parseFloat(scope.row.outAmount1) }}</div>
              <div v-else>金额：0.00</div>
              <div v-if="(scope.row.outAmount3 * 100 - scope.row.outAmount1 * 100) / 100 != 0">税额：{{
                ((scope.row.outAmount3 * 100
                  - scope.row.outAmount1 * 100) / 100).toFixed2() }}</div>
              <div v-else>税额：0.00</div>
            </div>
            <div v-if="scope.row.type == 1">
              <div v-if="scope.row.outAmount3 * 1">价税合计：{{ Number(scope.row.outAmount3).toFixed2() }}</div>
              <div v-else>价税合计：0.00</div>
              <div v-if="(scope.row.outAmount3 * 100 - scope.row.outAmount1 * 100) / 100 != 0">税额：{{
                ((scope.row.outAmount3 * 100
                  - scope.row.outAmount1 * 100) / 100).toFixed2() }}</div>
              <div v-else>税额：0.00</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="销项采集任务状态" min-width="200">
        <template #default="scope">
          <div class="div_p">
            <div class="item_icon">
              <p>
                <el-tooltip
                  :disabled="(scope.row.outErrLog ? scope.row.outErrLog : '' + scope.row.outBusinessLog ? scope.row.outBusinessLog : '') == '' ? true : false"
                  class="item" effect="dark" placement="top-start">
                  <template #content>
                    <div
                      v-html="scope.row.outErrLog ? scope.row.outErrLog : '' + scope.row.outBusinessLog ? scope.row.outBusinessLog : ''">
                    </div>
                  </template>
                  <div>
                    <div style="display:inline-block"
                      v-if="scope.row.outImg && (scope.row.outTaskStatus == 3 && scope.row.outBusinessStatus != 2)"
                      class="demo-image__preview">
                      <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;"
                        v-if="scope.row.outBusinessStatus == 5" @click="openOut(scope.row, scope.$index)"></i>
                      <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else
                        @click="openOut(scope.row, scope.$index)"></i>
                    </div>
                    <i v-else :class="$batchSendIconCj(scope.row.outTaskStatus, scope.row.outBusinessStatus)"></i>
                    <span style="padding-left: 0 ;"> {{ $batchSendCj(scope.row.outTaskStatus,
                      scope.row.outBusinessStatus) }}</span>
                    <span v-if="scope.row.outAmount2 != '' && scope.row.outBusinessStatus == 2"
                      style="margin-left: 5px;">{{
                        scope.row.outAmount2 }}张</span>
                    <i class="iconfont icon-wenhao"
                      v-if="(scope.row.outErrLog && scope.row.outErrLog != '成功') || (scope.row.outBusinessLog && scope.row.outBusinessLog != '成功')"
                      style="font-size:15px;color: red;margin-left: 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <span>{{ scope.row.outEndAt }}</span>
            </div>
            <p style="padding-top: 12px;"><el-button @click="declareDetail(scope.row, 'out')" size="small"
                link>采集记录</el-button>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确认状态" width="83">
        <template #default="scope">
          <div class="item_icon">
            <i :class="$filterAutoStatusIcon(scope.row.djqr_invoice_status)"></i>
            <span>{{ $filterAutoStatusText(scope.row.djqr_invoice_status, 'qr') }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确认人" width="75">
        <template #default="scope">
          <div v-if="[3, 6].includes(scope.row.djqr_invoice_status)">{{ scope.row.billConfirmUserName ?
            scope.row.billConfirmUserName : '系统自动' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="90" fixed="right">
        <template #default="scope">
          <el-button @click="handleCheckInvoiceDetail(scope.row)" size="small" link style="padding:5px 0"
            icon="Document" :disabled="!$buttonStatus('zdjz_fpmx')">明细</el-button>
          <!-- <el-button @click="confirm(scope.row)" size="small" link :disabled="scope.row.djqr_invoice_status != 2 || !$buttonStatus('zdjz_fpqr')" style="padding:5px 0">发票确认</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" />
    </div>
  </div>
  <collectInvoiceDetail ref="collectInvoiceDetail" @success="getList()" />
  <!-- 采集记录 -->
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <abnormalCom ref="abnormalCom" />
  <loginTaxAlert ref="loginTaxAlert" title="微信转发助手"></loginTaxAlert>
  <wechatMessage ref="wechatMessage" @success="getList"/>

</template>
<script>
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { autoCjCompanyList, confirmAutomaticBill, automaticStatInvoice } from '@/api/automatic'
import { currentAccountPeriod } from "@/utils";
import {companyEaSetWxNc} from "@/api/company"
import collectInvoiceDetail from '../batch/components/collectInvoiceDetail.vue'
import addressList from "@/components/Screening/address";
import declareDetails from '../batch/components/declareDetails.vue'
import sbImgUrl from "../batch/components/sbImgUrl.vue";
import abnormalCom from './components/abnormalCom.vue'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import selectBussinessCjStatus from "../batch/components/select/selectBussinessCjStatus.vue";
import axios from "axios";
import wechatMessage from './components/wechatMessage.vue'
export default {
  name: "automaticInvoice",
  components: {
    collectInvoiceDetail,
    selecttaxtype,
    addressList,
    declareDetails,
    sbImgUrl,
    abnormalCom,
    TagNameCopy,
    TableSortCell,
    selectAccreditStatus,
    selectBussinessCjStatus,
    wechatMessage
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        period: currentAccountPeriod(),
        autoMatic: 1,
        method: 'invoice',
        isNot0: 0
      },
      list: [],
      selects: [],
      total: 0,
      loading: false,
      contentStyleObj: {},
      info: {},
      sendLoading: false,
      maps: {
        inAmount2: "进项发票采集张数",
        inAmount3: "进项价税合计",
        inAmountTax: "进项税额",
        outAmount2: "销项发票采集张数",
        outAmount3: "销项价税合计",
        outAmountTax: "销项税额",
      }
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(260)
    this.getList()
    this.getInfo()
  },
  methods: {
    getInfo() {
      automaticStatInvoice(this.listQuery).then(res => {
        if (res.data.msg == 'success') {
          this.info = res.data.data
        }
      })
    },
    getList() {
      this.loading = true
      autoCjCompanyList(this.listQuery).then(res => {
        this.loading = false
        if (res.data.msg == 'success') {
          this.list = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
        }
      })
    },
    getNewList() {
      this.getList()
      this.getInfo()
    },
    getNewListTotal(e) {
      this.listQuery.invoiceStatusTotal = e ? e : null
      this.getNewList()
    },
    handleSelectionChange(e) {
      this.selects = e
    },
    //发票明细
    handleCheckInvoiceDetail(row) {
      this.$refs.collectInvoiceDetail.init(row, this.listQuery.period, 'djqr')
    },
    // 采集记录
    declareDetail(e, type) {
      if (type == 'in') {
        this.$refs.declareDetails.init(e.comId, this.listQuery.period, 'tax-cj-inInvoice')
      } else {
        this.$refs.declareDetails.init(e.comId, this.listQuery.period, 'tax-cj-outInvoice')
      }
    },
    open(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.inImg ? 'https://file.listensoft.net' + res.inImg + "?" + Math.random(10) : ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    openOut(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.outImg ? 'https://file.listensoft.net' + res.outImg + "?" + Math.random(10) : ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    // 表格排序
    sortChange(data) {
      if (data.order == "descending") {
        this.listQuery.desc = 1
      } else {
        this.listQuery.desc = 0
      }
      this.getList()
    },
    confirm(row) {
      this.$confirm('发票明细是否已确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmAutomaticBill([{
          comId: row.comId,
          period: this.listQuery.period,
          type: 'invoice'
        }]).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage('操作成功');
            this.getList()
            this.$bus.emit('confirmStatusUpdate')
          }
        })
      });
    },
    setWechat(row) {
      this.$prompt("请输入微信昵称/微信群名称", "绑定微信昵称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.wxNc,
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            let value = instance.inputValue
            let param = {
              ids: [row.comId],
              wxNc: value,
            }
            companyEaSetWxNc(param).then((res) => {
              done()
              if (res.data.msg == "success") {
                this.$qzfMessage('设置成功')
                this.getList()
              }
            })
          } else {
            done()
          }
        },
      })
    },
    calculateTax(amount3, amount1) {
      return Number(((amount3 * 100 - amount1 * 100) / 100).toFixed(2));
    },
    //复制发票
    copyTax() {
      let year = this.listQuery.period.slice(0, 4)
      let month = this.listQuery.period.slice(4, 6)
      if (this.selects.length == 0) {
        this.$qzfMessage("至少选择一家公司", 1)
        return
      }
      // 基础字符串数组
      let rows = [];
      this.selects.forEach(v => {
        v.inAmountTax = this.calculateTax(v.inAmount3, v.inAmount1);
        v.outAmountTax = this.calculateTax(v.outAmount3, v.outAmount1);
        let row = `您的企业「${v.name}」，${year}年${month}账期：`
        Object.keys(this.maps).forEach(key => {
          const taxName = this.maps[key];
          const taxValue = v[key] ? (v[key] * 1).toFixed(2) : 0;
          row += `\r${taxName}：${taxValue}${key == 'inAmount2' || key == 'outAmount2' ? '张' : '元'}`;
        })
        rows.push(row);
      })
      let str = [
        "老板您好，",
        ...rows,
        "注：以上数据均为电子税务局-税务数字账户采集获取，最终以月末实际汇总数据为准！"
      ].join("\r");

      this.$copyComName(str);
    },
    sendTax() {
      let year = this.listQuery.period.slice(0, 4)
      let month = this.listQuery.period.slice(4, 6)
      if (this.selects.length == 0) {
        this.$qzfMessage("至少选择一家公司", 1)
        return
      }
      let fail = this.selects.find(v => !v.wxNc)
      if (fail) {
        this.$qzfMessage(`${fail.name}未绑定微信，请重新选择`, 1)
        return
      }
      let that = this;
      this.sendLoading = true
      let arr = []
      this.selects.forEach((v => {
        let row = `您的企业「${v.name}」，${year}年${month}账期：`
        v.inAmountTax = this.calculateTax(v.inAmount3, v.inAmount1);
        v.outAmountTax = this.calculateTax(v.outAmount3, v.outAmount1);
        Object.keys(this.maps).forEach(key => {
          const taxName = this.maps[key];
          const taxValue = v[key] ? (v[key] * 1).toFixed(2) : 0;
          row += `\n${taxName}：${taxValue}${key == 'inAmount2' || key == 'outAmount2' ? '张' : '元'}`;
        })
        row += `\n注：以上数据均为电子税务局-税务数字账户采集获取，最终以月末实际汇总数据为准！`
        arr.push({
          wxName: v.wxNc,
          msg: row
        })
      }))
      this.sendLoading = false
      axios
        .post("http://localhost:8002/doTask/wx", arr)
        .then((res) => {
        })
        .catch(function () {
          setTimeout(() => {
            that.$refs.loginTaxAlert.dialogVisible = true;
          }, 1000);
        });
    },
     //绑定微信
     bindWechat() {
      this.$refs.wechatMessage.init()
    },
    confirmAll() {
      if (this.selects.length == 0) {
        this.$qzfMessage('请选择公司', 1);
        return
      }
      const arr = this.selects.filter(v => v.djqr_invoice_status == 2)
      if(arr && arr.length != 0){
        this.$confirm('确定要批量确认发票吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = arr.map((v) => ({
            comId: v.comId,
            period: this.listQuery.period,
            type: "invoice",
          }));
          confirmAutomaticBill(ids).then(res => {
            if (res.data.msg == 'success') {
              this.$qzfMessage('操作成功');
              this.getList()
              this.$bus.emit('confirmStatusUpdate')
            }
          })
        });
      }else{
        this.$qzfMessage('您所勾选的公司中无待确认的发票信息', 1);
      }
     
    },
    //重置
    cancel() {
      let originLimit = this.listQuery.limit
      this.listQuery = {
        page: 1,
        limit: originLimit,
        period: currentAccountPeriod(),
        autoMatic: 1,
        method: "invoice",
        isNot0: 0
      }
      this.getList()
    },
    getWjz() {
      this.$refs.abnormalCom.init(this.listQuery.period)
    }
  },
};
</script>

<style scoped lang="scss">
.auto_content{
  margin: 0 0 5px;
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  .left_select {
    display: flex;
    align-items: center;
  }
}

.item_icon {
  cursor: pointer;

  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }

  p {
    font-size: 13px;
    color: #333;
  }
}

.div_p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-weixintuisong {
  color: #15ba11;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: 2px;
}
</style>
